

.profile-container {
    position: relative;
    width: 50px;
    height: 50px;

    .profile-pic {
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        z-index: 1;
    }
    
    .progress-bar{
        width: 100%;
        height: 100%; 
        z-index: 2;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;

        .progress-ring {
            transition: stroke-dashoffset 0.5s ease-out;
        }
    }
    
}
  