@import '../../styles/mediaqueries.scss';

.header{
    width:100%;
    height: 100%;
    background-color: #151514;
    background: #14151C;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    //background-color: #202020;
    //background-color: transparent;
    //font-family: "Montserrat", "HelveticaNeue-Medium", "Helvetica Neue Medium", "Helvetica Neue", Helvetica, BlinkMacSystemFont, Arial, "Lucida Grande", sans-serif;
    font-family: 'Rajdhani', sans-serif;
    &.fade-out{
        animation: fadeOut 0.5s ease-in-out;
        animation-fill-mode: forwards;
        overflow-y:hidden;
    }
    &.minimal{
       background: #151514;

       .first-row{
        border-bottom: none;
       }
    }
    @keyframes fadeOut {
        0% {
            opacity: 1;
            max-height:200px;
        }
        100% {
            opacity: 0;
            max-height:0;

        }
    }
    .mobile_only{
        display: none !important;
        @include media(md){
            display: flex !important;
        }
    }
    .desktop_only{
        display: flex !important;
        @include media(md){
            display: none!important;
        }
    }
    .first-row{
        width: 100%;
        padding: 13px 25px;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1001;
        @include media(md){
            padding: 10px;
        }
        &.hidden{
            display: none;
        }
        .wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 96%;
            max-width: 1760px;
            position: relative;
            @include media(md2){
                flex-wrap: wrap;
            }
            .logo{
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                width: 270px;
                @include media(sm2){
                    width: auto;
                }
                .pic{
                    display: none;
                    @include media(sm2){
                        display: block;
                    }
                }
                .name{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: flex-start;
                    margin-left: 8px;
                    padding-top: 6px;

                    @include media(sm2){
                        display: none;
                    }
                    .brand_subtitle{
                        margin-left: auto;
                        margin-right:auto;
                        justify-self: flex-end;
                        color: #505050;
                        font-family: "HelveticaNeue", "Helvetica Neue", Helvetica, BlinkMacSystemFont, Arial, "Lucida Grande", sans-serif;
                        font-size: 14px;
                        line-height: 1.42857;
                    }
                }
            }
            .search-bar{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                height: 48px;
                cursor: pointer;
                gap:4px; 
                background: #0b0c10;
                border-color: #0b0c10;
                flex:1;
                max-width: 300px;
                width: 100%;
                transition: max-width 250ms cubic-bezier(.4,0,.2,1);
                border-radius: 32px;
                overflow: hidden;
                position: absolute;
                top:0;
                left:0;
                right: 0;
                margin: auto;
                z-index:4;
                @include media(md2){
                    position: relative;
                    width:100%;
                    max-width: 100%;
                    margin:16px 0;
                    order: 2;
                    flex:unset;
                }
                
                .logo{
                    //filter: grayscale(1) brightness(0) invert(1);
                    margin-left: 12px;
                    margin-right: 12px;
                    width: 30px;
                    opacity: 0;
                    display: none;
                    transition: max-width 250ms cubic-bezier(.4,0,.2,1);
                }
                &.focused{
                    max-width: 600px;
                    animation: makeVisible 1ms linear 600ms forwards;
                    @include media(xl){
                        width: calc(100% + 5px);
                        max-width: calc(100% + 5px);
                    }
                    .chip{
                        margin-left: 6px;
                    }
                    .logo{
                        @include media(xl){
                            display: flex;
                            opacity: 1;
                        }
                        @include media(md2){
                            display: none;
                        }
                    }
                }
                @keyframes makeVisible {
                    from {
                        overflow: hidden;
                    }
                    to {
                        overflow: visible;
                    }
                }                  
                .chip{
                    display: flex;
                    align-items:center;
                    justify-content: flex-start;
                    gap: 1em;
                    
                    background: #3f404f;
                    color: #d6cbcb;
                    border-radius: 32px;
                    padding: 0 16px;
                    height: 100%;
                    max-height: calc(100% - 12px);
                    margin: 6px;
                    font-size: 16px;
                    min-width: 170px;
                    margin-left: -180px;
                    transition: all 250ms cubic-bezier(.4,0,.2,1);
                    //transition-delay: 150ms;
                    align-self: flex-start;
                    position: relative;
                    overflow: hidden;
                    @include media(md2){
                        display: none;
                    }
                    .chevron-icon{
                        font-size: 6.4px;
                        transition: all 300ms cubic-bezier(.4,0,.2,1);
                    }
                    &.expanded{
                        overflow: visible;
                        .options{
                            opacity: 1;
                        }
                        .chevron-icon{
                            transform: rotate(-180deg);
                        }
                    }
                    .options{
                        display: flex;
                        flex-direction: column;
                        gap:16px;
                        align-items: flex-start;
                        justify-content: flex-start;
                        border-radius: 4px;
                        background: #1f2027;
                        transition: all 550ms cubic-bezier(.4,0,.2,1);
                        position: absolute;
                        top: calc(100% + 16px);
                        left:0;
                        right:0;
                        margin: auto;
                        width: 100%;
                        height: max-content;
                        display: flex;
                        align-items: stretch;
                        justify-content: flex-start;
                        padding: 3.2px;
                        gap: 8px;
                        opacity: 0;
                        z-index:1002;
                        box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px;
                        .item{
                            display: flex;
                            gap: 16px;
                            align-items: center;
                            justify-content: space-between;
                            padding:6.4px;
                            border-radius: 4px;
                            background: #2c2d36;
                            &:hover{
                                background: #3f404f;
                                
                            }
                            &.selected{
                                background: #5e5e5e;
                                background: #3f404f;
                                &:hover{
                                    background: #3f404f;
                                }
                            }
                            span{
                                font-size: 12.8px;
                            }
                            .glyphicon{
                                color: #9e9cb0;
                            }
                        }
                    }
                }
                #header-search-bar-input, input{
                    padding: 6px 12px;
                    flex:1;
                    max-width: 500px;
                    background: none;
                    border:none;
                    outline: none;
                    font-size: 16px;
                    font-family: 16px 'Rajdhani', sans-serif;
                    color: #d6cbcb;
                    margin-bottom: 0;
                    order:1;
                    @include media(xl){
                        max-width: 70%;
                    }
                    @include media(md2){
                        max-width: unset;
                    }
                }
                .close{
                    opacity: 0;
                    order: 2;
                    color:#d6cbcb;
                    font-size: 9.6px;
                    transition: all 550ms cubic-bezier(.4,0,.2,1);
                    &.visible{
                        opacity:1;
                    }
                }
                .form-btn{
                    color: #fff;
                    background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
                    //border-color: #dd7a02;
                    border: none;
                    width: 40px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                    border-top-right-radius: 32px;
                    border-bottom-right-radius: 32px;
                    order: 3;

                    transition:background-position 1000ms ease;
                    &:hover{
                        //background-color: #ec8202;
                        background-size: 400% 400%;
                        background-position:100% 50%;
                        //background: linear-gradient(123deg, #E23B17 25.9%, #F36C17 89.26%);
                        //border-color: #d37402;

                    }
                    &.btn-setting{
                        order:2;
                        border-radius: 0;
                        margin-left: auto;
                        border-right: 1px solid #563205;

                        &.active{
                            & + .submenu{
                                max-height: 200px;
                                border-width: 1px;
                                padding: 5px 0;
                            }
                        }
                    }
                    &.btn-search{
                        background: transparent;
                        margin-left:auto;
                        margin-right: 12px;
                    }
                }
                .submenu{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: flex-start;
                    padding: 0;
                    position: absolute;
                    right:0;
                    top:100%;
                    color: #fff;
                    background-color: #333;
                    border: 1px solid #808080;
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;
                    box-shadow: 2px 6px 8px -1px rgba(0, 0, 0, 0.5);
                    font-size: 14px;
                    z-index:3;
                    gap:2px;
                    overflow: hidden;
                    transition: all 200ms ease-in-out;
                    max-height: 0;
                    border-width: 0;

                    .item{
                        display: inline-flex;
                        align-items: center;
                        font-size: 14px;
                        padding:4px;
                        cursor: pointer;
                        &:hover{
                            color:#F36C17;
                        }
                        &:before{
                            content: "\e817";
                            font-family: "crateicons";
                            margin-right: 4px;
                        }
                        &.checked{
                            &:before{
                                content: "\e81f";
                            }
                        }
                    }
                }
            }
            .account-menu{
                position: relative;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                gap: 8px;
                color: #eee;
                //margin-left:24px;
                //flex:1;
                z-index:1005;
                max-width: 270px;
                .glyphicon{
                    font-size: 14px;
                    @include media(md){
                        display: none;
                    }
                }
                .username{
                    font-weight: 700;
                    
                    font-size: 16px;
                    line-height: 1.42857;
                    text-overflow: ellipsis;
                    max-width: 250px;
                    white-space: nowrap;
                    text-align: center;
                    @include media(md){
                        display: none;
                    }
                }
                .profile-pic{
                    border-radius: 50%;
                    min-width: 42px;
                    width: 42px;
                    height: 42px;
                    background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    img{
                        width:100%;
                        height: auto;
                        object-fit: cover;
                    }
                }
                
                .submenu{
                    position: absolute;
                    top:100%;
                    z-index: 4;
                    width: max-content;
                    max-width: 80vw;
                    right:0;
                    display: flex;
                    flex-direction: column;

                    background-color: #333;
                    border: 1px solid #808080;
                    box-shadow: 2px 6px 8px -1px rgba(0, 0, 0, 0.5);

                    transition: all 200ms ease-in-out;
                    overflow: hidden;
                    max-height: 0;
                    border-width: 0;

                    .item{
                        display: inline-flex;
                        align-items: center;
                        gap:8px;
                        padding: 8px 10px;
                        color: #fff;
                        font-size: 16px;
                        font-weight: 500;
                        cursor: pointer;
                        &:hover{
                            color:#F36C17;
                        }
                        &.head{
                            display: inline-flex;
                            padding: 8px 10px;
                            border-bottom: 1px solid #808080;
                            font-size:16px;
                            font-style: italic;
                            cursor: unset;
                            &:hover{
                                color:inherit;
                            }
                        }
                    }
                    &.is-logged-out{
                        .item.show-logged-out{
                            display: inline-flex;
                        }
                    }
                    &.is-logged-free{
                        .item.show-logged-free{
                            display: inline-flex;
                        }
                    }
                    &.is-logged-pro{
                        .item.show-logged-pro{
                            display: inline-flex;
                        }
                    }
                }

                &:hover{
                    .submenu{
                        max-height: 300px;
                        border-width: 1px;
                    }
                }
                
            }
            .menu-bar{
                display: none;
    
                @include media(md){
                    display: flex;
                    width: 30px;
                    filter: invert(1);
                }
            }
        }
    }
    .second-row{
        width: 100%;
        padding: 13px 25px;
        padding-bottom: 36px;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
        @include media(md){
            padding: 0;
        }
        .wrapper{
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            max-width: 100%;
            flex-wrap: wrap;
            @include media(md2){
                flex-direction: column;
            }

            .menew{
                display: flex;
                flex-direction: column;
                align-items: center;
                font-weight: 600;
                text-transform: capitalize;
                max-width: 1440px;
                .top_menu{
                    display: flex;
                    align-items: center;
                    gap:32px;
                    .heading{
                        position: relative;
                        background: red;
                        color: #FFF;
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        cursor: pointer;
                        line-height: normal;
                        padding-bottom: 16px;

                        .glyphicon{
                            font-size: 8px;
                            transition: all 350ms ease;
                        }
                        &:hover{
                            .glyphicon{
                                transform: rotate(-180deg);
                            }
                            .content_wrapper{
                                animation: expandDropdown 300ms cubic-bezier(0.4, 0, 0.2, 1) forwards, overflowVisible 1ms 300ms forwards;
                            }
                        }
                        @keyframes expandDropdown {
                            0% {
                                max-height: 0px;
                                padding: 0;
                            }
                            100% {
                                max-height: 1000px; /* Ajustá este valor según el contenido */
                                padding: 16px;
                            }
                        }
                        
                        @keyframes overflowVisible {
                            0% {
                                overflow: hidden;
                            }
                            100% {
                                overflow: visible;
                            }
                        }
                        .content_wrapper{
                            display: flex;
                            flex-direction: row;
                            //gap: 16px;
                            flex-wrap: wrap;
                            align-items: flex-start;
                            justify-content: flex-start;
                            width: max-content;
                            max-width: 400px;
                            height: auto;
                            
                            overflow: hidden;
                            background: rgba(21, 21, 20, 0.75);
                            background: magenta;
                            padding: 0px;
                            position: absolute;
                            left:50%;
                            transform: translateX(-50%);
                            backdrop-filter: blur(5px);
                            border-radius: 10px;
                            top: 100%;
                            z-index: 2;
                            box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;

                            max-height: 0px;
                            animation: none;
                            transition:all 300ms cubic-bezier(0.4, 0, 0.2, 1);
                            

                            .menu{
                                flex:1;
                                min-width: 150px;
                                background: black;
                                border: 1px solid blue;
                                position: relative;
                                display: flex;
                                align-items: center;
                                padding: 8px;
                                flex-direction: column;
                                height: max-content;
                                &:hover{
                                    .submenu{
                                        max-height: 600px;
                                    }
                                }
                                .submenu{
                                    display: flex;
                                    flex-direction: column;
                                    //flex-wrap: wrap;
                                    gap: 16px;
                                    align-items: center;
                                    justify-content: flex-start;
                                    width: max-content;
                                    max-width: 400px;
                                    height: auto;
                                    max-height: 0px;
                                    overflow: hidden;
                                    background: rgba(21, 21, 20, 0.75);
                                    
                                    backdrop-filter: blur(5px);
                                    border-radius: 10px;
                                    
                                    z-index: 2;
                                    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
                                    transition:all 300ms cubic-bezier(0.4, 0, 0.2, 1);

                                    position: absolute;
                                    left:50%;
                                    transform: translateX(-50%);
                                    top: 100%;

                                }
                            }
                        }
                    }
                }
                
            }
            
        }
    }
    .controls{
        //flex:1;
        flex: unset;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap:8px;

        @include media(md2){
            width: max-content;
        }
        .item{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 2px solid #eee;
            border-radius: .4px;
            height: 32px;
            line-height: 28px;
            color: #eee;
            padding: 0 8px;
            font-weight: 700;
            font-size: 14px;
            min-width: max-content;
            text-transform: uppercase;
            &.accent{
                background-size: 300% 100%;
                background-image: linear-gradient(123deg, #f36c17 10%, #e23b17 30% 45%, #f38e17 55% 70%, #e26c17);
                //background: linear-gradient(123deg, #F36C17 25.9%, #E23B17 89.26%);
                border-color:transparent;
                color: #fff;
                min-width: 120px;

                &:hover{
                    background: linear-gradient(60deg, #F36C17, #f37055, #E23B17, #ec2c01,#E23B17, #F36C17, #f7b722, #f7f022 );
                    animation: animatedgradient 3s ease alternate infinite;
                    background-size: 300% 300%;
                    text-decoration: none;
                    border-color:transparent;
                }
            }
            @keyframes animatedgradient {
                0%{background-position:0% 50%}
                50%{background-position:100% 50%}
                100%{background-position:0% 50%}
            }
            &:hover{
                border-color:#F36C17;
                text-decoration: none;
            }
            &.item{
                &.hamburger-menu{
                    &.hamburger-menu{
                        display: none;
                        border-width: 1px;
                        width: 44px;
                        position: relative;
                        @include media(md2){
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            gap: 5px;
                            border: 1px solid gray;
                        }
                        .icon-bar{
                            background-color: #fff;
                            width: 22px;
                            height: 2px;
                            border-radius: 1px;
                            transition: all 0.2s ease-in-out;
                        }
                        &.close{
                            .icon-bar{
                                position: absolute;
                                left:0;
                                right:0;
                                margin:auto;
                                top:0;
                                bottom:0;
                                &:first-child{
                                    transform: rotate(45deg);
                                }
                                &:nth-child(2){
                                    width: 0;
                                }
                                &:last-child{
                                    transform: rotate(-45deg);
                                }
                            }
                        }
                    }
                }
            }
        }
        
        &.is-logged-pro{
            .item.show-logged-pro{
                display: flex;
            }
        }
        &.is-logged-free{
            .item.show-logged-free{
                display: flex;
            }
        }
        &.is-logged-out{
            .item.show-logged-out{
                display: flex;
            }
        }

        .menu-bar{
            display: none;

            @include media(md){
                display: flex;
                width: 30px;
                filter: invert(1);
            }
        }
        
    }
    .banner{
        width: 100%;
        padding: 8px 20px;
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background-color: #ec8202;
        .content{
            max-width: 1120px;
            width: 100%;
            display: inline-flex;
            align-items: center;
            justify-content: flex-start;
            color: #FFF;
            font-size: 16px;
            cursor: pointer;

            @include media(md){
                display: inline;
            }
            a{
                margin: 0 8px;
                text-decoration: underline;
                color: #FFF;
                font-style: italic;

                @include media(md){
                    margin:0;
                }
            }
        }
        .progress_bar_wrapper{
            width: 720px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            margin-top: 8px;
            border: 1px solid #8c2d077a;
            border-radius: 4px;
            overflow: hidden;
            .progress_bar{
                width: 100%;
                height: 4px;
                background: #FFF;
                border-radius: 2px;
                position: relative;
                overflow: hidden;
                .progress{
                    width: 0;
                    height: 100%;
                    background: #FF9800;
                    position: absolute;
                    top:0;
                    left:0;
                    transition: width 1s cubic-bezier(0.4, 0, 0.2, 1);
                }
            }
        }
    }
}

  
